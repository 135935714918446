<template>
  <div class="footer-section body">
    <div class="footer-container">
      <div class="w-layout-grid grid">
        <div id="w-node-8a8f9e2e7dc6-9e2e7dc3" class="footer-social-media">
          <div class="social-media-icon cursorLinkPointer"
          @click="$router.absUrl('https://www.facebook.com/VedantaSpain/')"
          >
            <img src="images/vedanta-facebook.svg" loading="lazy" alt="" />
          </div>
          <div class="social-media-icon cursorLinkPointer"
          @click="$router.absUrl('https://www.instagram.com/vedantaspain/')"
          >
            <img src="images/vedanta-instagram.svg" loading="lazy" alt="" />
          </div>
          <a href="mailto:info@vedantaspain.com" class="social-media-icon">
            <img src="images/vedanta-mail.svg" loading="lazy" alt="" />
          </a>
          <a href="tel:+34697251222" class="social-media-icon">
            <img src="images/vedanta-phone.svg" loading="lazy" alt="" />
          </a>
        </div>
        <div id="w-node-8a8f9e2e7dcf-9e2e7dc3" class="footer-logo-container">
          <img
            src="images/vedanta-spain-logo.svg"
            loading="lazy"
            alt="vedanta-logo"
            class="image"
          />
        </div>
        <div id="w-node-8a8f9e2e7dd1-9e2e7dc3" class="footer-space"></div>
        <div
          id="w-node-8a8f9e2e7dd2-9e2e7dc3"
          class="copyright-footer privacy-policy"
        >
          <div class="copyright-footer-container">
            <span
              @click="$router.openNewTab('/terms-conditions')"
              class="cursorLinkPointer"
              >Terms &amp; Conditions
            </span>
            | <span 
              @click="$router.openNewTab('/privacy-policy')"
            class="cursorLinkPointer">Privacy Policy</span>
          </div>
        </div>
        <div id="w-node-4025741fff11-9e2e7dc3" class="copyright-footer">
          <div class="copyright">Vedanta Spain © All Rights Reserved</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
    };
  },
  methods: {
    
   
    
  },
};
</script>

<style>

</style>
