<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <app-bar />
    </v-app-bar>

    <v-main class="body">
      <router-view />
      <cookie-policy v-if="!cookiePolicyAccepted"></cookie-policy>
      <v-snackbar v-model="openSnackBar" :color="snackBarColor" :timeout="snackBarTimeout">
      <v-row class="pa-0">
        <v-col class="text-center pa-0">{{ snackBarText }}</v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn
        v-if="!snackBarTimeout"
          color="black"
          text
          v-bind="attrs"
          @click="openSnackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="errorDialogOpen"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{errorDialogTitle}}
        </v-card-title>
        <v-card-text>{{ errorDialogText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="green darken-1"
            text
            @click="errorDialogOpen = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import CookiePolicy from "./components/CookiePolicy";

export default {
  name: "App",

  components: {
    AppBar,
    Footer,
    CookiePolicy,
  },
  computed: {
    snackBarText () {
      return this.$store.getters.snackBarText
    },
    snackBarColor () {
      return this.$store.getters.snackBarColor
    },
    snackBarTimeout () {
      return this.$store.getters.snackBarTimeout
    },
    openSnackBar: {
      get() {
        return this.$store.getters.openSnackBar
      },
      set(value) {
        this.$store.dispatch("setOpenSnackBar", value)
      }
    },
    cookiePolicyAccepted() {
      return this.$store.getters.cookiePolicyAccepted;
    },
    errorDialogOpen: {
      get() {
        return this.$store.getters.errorDialogOpen
      },
      set(value) {
        this.$store.dispatch("setErrorDialogOpen", value)
      }
    },
    errorDialogTitle () {
      return this.$store.getters.errorDialogTitle
    },
    errorDialogText () {
      return this.$store.getters.errorDialogText
    },
  },
  async mounted() {
    var cookiePresent = false;
    cookiePresent = this.$cookies.isKey("cookiePolicyAccepted");
    console.log("Cookie accepted? : ", cookiePresent);
    if (cookiePresent) {
      this.$store.dispatch("setCookiePolicyAccepted", true);
    } else {
      this.$store.dispatch("setCookiePolicyAccepted", false);
    }
    console.log("Cookie accepted? : ", cookiePresent);
    try {
      await this.$store.dispatch("getMonthlyTalksImageUrl");
      await this.$store.dispatch("getStudyClassesImageUrl");
      var url = this.$store.getters.monthlyTalksImageUrl;
    console.log("Url : ", url);
      /* const image = new Image();
      image.src = this.$store.getters.monthlyTalksImageUrl */
      await this.$imagePreload([url], (e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  },

  data: () => ({
    //
  }),
};
</script>
<style>
.v-application a {
  color: black !important;
}
.navbar-item.w--current {
  color: #fff !important;
}

.container {
  max-width: 1200px !important;
}
.cursorLinkPointer {
  cursor: pointer;
}
</style>
