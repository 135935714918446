<template>
  <div id="cookie-policy-banner" class="cookie-banner">
      <div class="cookie-logo"><img src="images/vedanta.webp" loading="lazy" width="44" alt=""></div>
      <div class="cookie-header">
        <div class="cookie-text-header">Notice</div>
      </div>
      <div class="cookie-text-container">
        <div class="cookie-text">This website or its third-party tools use cookies, which are necessary for its functioning and required to achieve the purposes illustrated in the cookie policy. You accept the use of cookies by closing or dismissing this notice, by scrolling this page, by clicking a link or button or by continuing to browse otherwise.</div>
      </div>
      <div @click="acceptPolicy()" class="cookie-buttons-container cursorLinkPointer">
        <div class="accept-button">
          <div class="text-block-3">Accept</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'CookiePolicy',
  mounted() {
  },
  methods: {
      acceptPolicy() {
          this.$store.dispatch("setCookiePolicyAccepted", true)
          this.$cookies.set("cookiePolicyAccepted", "true", "1y");
      }
  }
 
}
</script>

<style>

</style>
