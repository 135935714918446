import firebase from 'firebase'
import 'firebase/firestore'

// firebase init goes here
const config = {
  apiKey: "AIzaSyDTy6d_YICuH28HYueWhut0hsOXgGEUsJw",
  authDomain: "vedanta-c8d36.firebaseapp.com",
  databaseURL: "https://vedanta-c8d36.firebaseio.com",
  projectId: "vedanta-c8d36",
  storageBucket: "vedanta-c8d36.appspot.com",
  messagingSenderId: "1065525281833",
  appId: "1:1065525281833:web:a226f8006903f4e57f0838",
  measurementId: "G-QVJX7YTD8L"
}
firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const authObject = firebase.auth
const functions = firebase.functions()
const currentUser = auth.currentUser
const storage = firebase.storage()
const imageStorage = firebase.app().storage("gs://vedanta-images")

// firebase collections
const usersCollection = db.collection('users')
const productsCollection = db.collection('products')
const categoriesCollection = db.collection('categories')
const ordersCollection = db.collection('orders')
const reviewsCollection = db.collection('reviews')
const enquiriesCollection = db.collection('enquiries')
const eventsCollection = db.collection("events")
const websiteDataCollection = db.collection("websiteData")
const imageStorageRef = imageStorage.ref();

export {
  db,
  auth,
  authObject,
  functions,
  storage,
  currentUser,
  usersCollection,
  productsCollection,
  categoriesCollection,
  eventsCollection,
  websiteDataCollection,
  ordersCollection,
  reviewsCollection,
  imageStorageRef,
  enquiriesCollection
}
