import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import checkView from 'vue-check-view'
import vueScrollTo from 'vue-scrollto'
import VueCookies from 'vue-cookies'
import imagePreloader from 'vue-image-preloader'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Madrid')

Vue.config.productionTip = false
Vue.use(checkView)
Vue.use(vueScrollTo)
Vue.use(VueCookies)
Vue.use(imagePreloader)

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
