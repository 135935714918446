const firebaseConf = require('../../firebase/firebaseConfig')
export default {
  state: {
    confirmLoading: false
  },
  mutations: {
    setConfirmLoading (state, payload) {
      state.loading = payload
    }
  },
  actions: {
    setConfirmLoading ({ commit }, payload) {
      commit('setConfirmLoading', payload)
    },
    storeUserEnquiry ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('setConfirmLoading', true)
        console.log('Store user enquiry: ', payload)

        console.log('Adding data: ', payload)
        setTimeout(() => {
          console.log('Timed out - resolving ', payload.id)

          resolve()
        }, 5000)
        try {
          firebaseConf.usersCollection.doc(payload.userId).collection('enquiries').doc(payload.id).set(payload)
            .then(function (data) {
              console.log('Stored data: ', data)
              resolve()
            })
            .catch(function (err) {
              console.log('Error: ', err)
              commit('setConfirmLoading', false)
              reject(err)
            })
        } catch (err) {
          console.error('Catch error', err)
        }
      })
    },
    storeUserFeedback ({ commit }, payload) {
      commit('setConfirmLoading', true)
      console.log('Store user feedback: ', payload)
      return new Promise((resolve, reject) => {
        console.log('Adding data: ', payload)
        try {
          setTimeout(() => {
            console.log('Timed out - resolving ', payload.id)

            resolve()
          }, 5000)
          firebaseConf.usersCollection.doc(payload.userId).collection('feedbacks').doc(payload.id).set(payload)
            .then(() => {
              console.log('Added data ', payload.id)
              commit('setConfirmLoading', false)

              resolve()
            })
            .catch((err) => {
              console.log('Error: ', err)
              commit('setConfirmLoading', false)
              reject(err)
            })
        } catch (error) {
          console.error('Catch error', error)
        }
      })
    },
    logout () {
      firebaseConf.auth.signOut().then(() => {
        console.log('Signed out')
      })
        .catch((err) => {
          console.log('Error signing out: ', err)
        })
    }

  },
  getters: {
    confirmLoading (state) {
      return state.confirmLoading
    }
  }
}
