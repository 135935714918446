const firebaseConf = require('../../firebase/firebaseConfig')
const listAllEvents = firebaseConf.functions.httpsCallable('listAllEvents')
const sendMailOverHTTP = firebaseConf.functions.httpsCallable('sendMailOverHTTP')

export default {
    state: {
        saveFinished: false,
        events: [],
        calendarKey: 0,
        showNewEventModel: false,
        monthlyTalksImageUrl: '',
        studyClassesImageUrl: '',
        eventDialog: false,
        eventMailDialog: false,
        eventDisplayed: {}
    },
    mutations: {
        setMonthlyTalksImageUrl(state, payload) {
            state.monthlyTalksImageUrl = payload
        },
        setStudyClassesImageUrl(state, payload) {
            state.studyClassesImageUrl = payload
        },
        setEvents(state, payload) {
            state.events = payload
        },
        updateCalendar(state) {
            state.calendarKey++
        },
        setEventDialog(state, payload) {
            state.eventDialog = payload
        },
        setEventMailDialog(state, payload) {
            state.eventMailDialog = payload
        },
        setEventDisplayed(state, payload) {
            console.log("Event displayed:", payload);
            
            
            state.eventDisplayed = payload
        }
    },
    actions: {
        sendDetailsByEmail({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                console.log("Store user enquiry: ", payload);

                setTimeout(() => {

                    commit('setLoading', false)
                    resolve();
                }, 5000);

                // To send mail
                try {
                   dispatch("sendDetails", payload)
                   .then(function(data) {
                    console.log("Stored data: ", data);
                    commit('setLoading', false)
                    resolve();

                })
                .catch(function(err) {
                    console.log("Error: ", err);
                    commit('setLoading', false)
                    reject(err);
                })
                    
                } catch (error) {
                    commit('setLoading', false)
                    console.error("Catch error", error);
                    reject(error)
                }
               
            })
        },
        sendDetails ({ commit }, payload) {
            console.log("Send details");
            commit('setLoading', true)
      
            return new Promise((resolve, reject) => {

                setTimeout(() => {
                    commit('setLoading', false)

                    resolve();
                }, 10000);

                sendMailOverHTTP(payload)
                .then(result => {
                  console.log("Mail sent:", result);
                    commit('setLoading', false)
                    resolve(result)
                })
                .catch(err => {
                  console.log("Error: ", err)
                    commit('setLoading', false)
                    reject(err)
                })
            });
        },
        updateCalendar({ commit }) {
            commit('updateCalendar')
        },
        commitAllEvents({ commit }, payload) {
            commit('setEvents', payload);
        },
        setEventDisplayed({ commit }, payload) {
            commit('setEventDisplayed', payload);
        },
        setEventDialog({ commit }, payload) {
            commit('setEventDialog', payload);
        },
        setEventMailDialog({ commit }, payload) {
            commit('setEventMailDialog', payload);
        },
        getMonthlyTalksImageUrl({commit}) {
            return new Promise((resolve, reject) => {
                firebaseConf.websiteDataCollection.doc('monthlyTalksImage').get()
                .then((snapshot) => {
                    console.log('Monthly talks url: ', snapshot.data())
                    commit("setMonthlyTalksImageUrl", snapshot.data().url)
                    resolve()
                })
                .catch((error) => {
                    console.error(error)
                    reject(error)
                })
            })
        },
        getStudyClassesImageUrl({commit}) {
            return new Promise((resolve, reject) => {
                firebaseConf.websiteDataCollection.doc('studyClassesImage').get()
                .then((snapshot) => {
                    console.log('Study Classes url: ', snapshot.data())
                    commit("setStudyClassesImageUrl", snapshot.data().url)
                    resolve()
                })
                .catch((error) => {
                    console.error(error)
                    reject(error)
                })
            })
        },
        listAllEvents({ commit }) {
            commit("setLoading", true)
            console.log("Getting events: ")
            return new Promise(async (resolve) => {

                var events = await listAllEvents();
                console.log("Query snapshot: ", events.data)

                commit("setEvents", events.data)
                commit('updateCalendar')

                commit("setLoading", false)
                resolve(events)

            })
        },

    },
    getters: {
        monthlyTalksImageUrl (state) {
            return state.monthlyTalksImageUrl
        },
        studyClassesImageUrl (state) {
            return state.studyClassesImageUrl
        },
        events(state) {
            return state.events
        },
        calendarKey(state) {
            return state.calendarKey
        },
        eventDialog(state) {
            return state.eventDialog
        },
        eventDisplayed(state) {
            return state.eventDisplayed
        },
        eventMailDialog(state) {
            return state.eventMailDialog

        }
    }
}