<template>
  <div class="navbar-section">
    <div class="navbar-section">
      <div
        data-collapse="small"
        data-animation="default"
        data-duration="500"
        data-easing="ease-in"
        data-easing2="ease-out"
        data-doc-height="1"
        role="banner"
        class="navbar w-nav"
      >
        <div class="container w-container">
          <nav role="navigation" class="navbar-items-container w-nav-menu">
            <a
              @click="aboutUsClicked()"
              :class="currentPage == 'about-us' ? 'w--current' : ''"
              aria-current="page"
              class="navbar-item w-nav-link"
              >ABOUT US</a
            >
          <link rel="prerender" href="/about-us">
            <a
              @click="activitiesClicked()"
              :class="currentPage == 'activities' ? 'w--current' : ''"
              class="navbar-item w-nav-link"
              >ACTIVITIES</a
            >
          <link rel="prerender" href="/activities">
          </nav>
          <div class="navbar-logo-container">
            <a
              @click="$router.push('/')"
              :class="currentPage == 'home' ? 'w--current' : ''"
              class="vedanta-logo-container w-nav-brand"
              ><img
                src="images/vedanta-spain-logo.svg"
                height=""
                alt="vedanta-logo"
                class="vedanta-logo"
            /></a>
          <link rel="prerender" href="/">
          </div>
          <nav
            role="navigation"
            class="navbar-items-container navbar-correction second w-nav-menu"
          >
            <a
              @click="inspirationClicked()"
              :class="currentPage == 'inspiration' ? 'w--current' : ''"
              class="navbar-item w-nav-link"
              >INSPIRATION</a
            >
          <link rel="prerender" href="/inspiration">
            <a
              @click="contactUsClicked()"
              class="navbar-item w-nav-link"
              :class="currentPage == 'contact-us' ? 'w--current' : ''"
              >CONTACT US</a
            >
          <link rel="prerender" href="/contact-us">
          </nav>
          <div class="hamburger w-nav-button">
            <img src="images/hamburger.svg" loading="lazy" width="24" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBar",
  components: {},
  data() {
    return {
      navbarKey: 0,
      hover: false,
    };
  },
  computed: {
    currentPage() {
      return this.$route.path.substring(1, this.$route.path.length);
    },

  },
  methods: {
    beforeEnter(el) {
      console.log("Before enter: ", el);
    },
    enter(el, done) {
      console.log("Enter: ", el);
      done();
    },
    afterEnter(el) {
      console.log("After enter: ", el);
    },

    closeMenu() {
      if(this.$vuetify.breakpoint.width < 786) {
         var hamburgerIcon = document.getElementsByClassName("hamburger");
      hamburgerIcon[0].click();
      }
    },
    aboutUsClicked() {
      this.$router.push("/about-us");
      this.closeMenu()
    },
    activitiesClicked() {
      this.$router.push("/activities");
      this.closeMenu()
    },
    inspirationClicked() {
      this.$router.push("/inspiration");
      this.closeMenu()
    },
    contactUsClicked() {
      this.$router.push("/contact-us");
      this.closeMenu()
    },
  },
};
</script>

<style>
</style>
