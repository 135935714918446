import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Vedanta',
      metaTags: [
        {
          name: 'description',
          content: 'Improve your intellect; Improve your life'
        },
        {
          property: 'og:title',
          content: 'Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'Improve your intellect; Improve your life'
        }
      ]
    }
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/About-Us.vue'),
    meta: {
      title: 'About Us - Vedanta Spain',
      metaTags: [
        {
          name: 'description',
          content: 'About the people who started Vedanta Spain'
        },
        {
          property: 'og:title',
          content: 'About Us - Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'About the people who started Vedanta Spain'
        }
      ]
    }
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/Contact-Us.vue'),
    meta: {
      title: 'Contact Us - Vedanta Spain',
      metaTags: [
        {
          name: 'description',
          content: 'Contact us for more details'
        },
        {
          property: 'og:title',
          content: 'Contact Us - Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'Contact us for more details'
        }
      ]
    }
  },
  {
    path: '/inspiration',
    name: 'Inspiration',
    component: () => import(/* webpackChunkName: "inspiration" */ '../views/Inspiration.vue'),
    meta: {
      title: 'Inspiration - Vedanta Spain',
      metaTags: [
        {
          name: 'description',
          content: 'What inpired us to start Vedanta'
        },
        {
          property: 'og:title',
          content: 'Inspiration - Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'What inpired us to start Vedanta'
        }
      ]
    }
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import(/* webpackChunkName: "activities" */ '../views/Activities.vue'),
    meta: {
      title: 'Activities - Vedanta Spain',
      metaTags: [
        {
          name: 'description',
          content: 'List of activities in Vedanta'
        },
        {
          property: 'og:title',
          content: 'Activities - Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'List of activities in Vedanta'
        }
      ]
    }
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "terms-conditions" */ '../views/TermsAndConditions.vue'),
    meta: {
      title: 'Terms And Conditions - Vedanta Spain',
      metaTags: [
        {
          name: 'description',
          content: 'Terms And Conditions'
        },
        {
          property: 'og:title',
          content: 'Terms and Conditions - Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'Terms And Conditions'
        }
      ]
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy - Vedanta Spain',
      metaTags: [
        {
          name: 'description',
          content: 'Privacy Policy'
        },
        {
          property: 'og:title',
          content: 'Privacy Policy - Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'Privacy Policy'
        }
      ]
    }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue'),
    meta: {
      title: 'Page Not Found - Vedanta Spain',
      metaTags: [
        {
          name: 'description',
          content: 'Page Not Found'
        },
        {
          property: 'og:title',
          content: '404 - Vedanta Spain'
        },
        {
          property: 'og:description',
          content: 'Page Not Found'
        }
      ]
    }
  }
]

VueRouter.prototype.openNewTab = function (routeObject) {
  const {href} = this.resolve(routeObject)
  window.open(href, '_blank')
}

const router = new VueRouter({
  mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

/* Vue Router is not meant to handle absolute urls. */
/* So whenever we want to deal with those, we can use this.$router.absUrl(url) */
VueRouter.prototype.absUrl = function(url, newTab = true) {
  console.log('New tab')
  const link = document.createElement('a')
  link.href = url
  link.target = newTab ? '_blank' : ''
  if (newTab) link.rel = 'noopener noreferrer' // IMPORTANT to add this
  link.click()
}



router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // Unused - so commenting
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
    // .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
